import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { ApplicationState } from 'store';

import { Button, Badge } from 'reactstrap';
import { GenericClient, IUserLoginDetailsModel, ExecutionsClient, ExecutionsTierBooleanModel, ConversionOptionValueModel, ConversionOptionsClient } from '../../../../../client/ApiClient';
import { sendNotification } from '../../../../../services/Notifications';
import { SecureContainer } from '../../../../basic/Authentication';
import { DataTable, DataRow, DataCell, DataTableButton } from '../../../../basic/DataTable';
import Toolbar from '../../../../basic/Toolbar';
import { tierNames, migrateEntities, missingEntitiesTier2, missingEntitiesTier3, missingEntitiesTier4, missingEntitiesTier5, actionTypes } from '../../Constants';
import * as Roles from 'constants/Roles';
import { StatisticsClient, ConversionClient, ConversionModel, ExecutionsModel } from '../../../../../client/ApiClient';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';

import '../../../../../styles/Forms.scss';
import './StageActionToolbar.scss';
import './TierSummary.scss';
import { enqueueNotification } from '../../../../../store/Notifications';
import { actionCreators } from "../../../../../store/Boolean"
import { store } from "AppContext"

interface TierSummaryPageArgs {
    conversionId: string,
    tierId: string,
}

class StatisticsViewModel {
    entityType?: string | undefined;
    mapped?: number | undefined;
    notMapped?: number | undefined;
    isDropped?: number | undefined;
    total?: number | undefined;
    status?: any | undefined;
    statuses?: any | undefined;
    sectionId?: string | undefined;
    tier?: string | undefined;
    isMappable?: boolean | undefined;
}

interface TierSummaryPageState {
    items: StatisticsViewModel[];
    tierBooleans: ExecutionsTierBooleanModel | undefined;
    buttonLock: boolean;
    copyDisable: boolean;
    conversion: ConversionModel | undefined;
    hubConnection: HubConnection | null;
    executions: ExecutionsModel[];
    lastExecution: ExecutionsModel | undefined;
    entityType: string | undefined;
    loading: boolean;
    mainStatus: any;
    isMappable: boolean;
    options: { [key: string]: ConversionOptionValueModel } | undefined;
}


type TierSummaryPageProps = RouteComponentProps<TierSummaryPageArgs> & {
    currentUser: IUserLoginDetailsModel | undefined,
    isMappable: boolean,
};

class TierSummaryPage extends React.Component<TierSummaryPageProps, TierSummaryPageState>{
    private readonly client: GenericClient = new GenericClient();
    private readonly statisticsClient: StatisticsClient = new StatisticsClient();
    private executions: ExecutionsClient = new ExecutionsClient();
    private conversion: ConversionClient = new ConversionClient();
    private options: ConversionOptionsClient = new ConversionOptionsClient();
    private readonly unMatchedEntities = ["Dwelling Fire Form Type", "Homeowners Coverage Codes", "Homeowners Form Type", "Dwelling Fire Coverage Codes"]

    constructor(props: TierSummaryPageProps) {
        super(props);
        this.state = {
            items: [],
            tierBooleans: undefined,
            buttonLock: true,
            copyDisable: false,
            conversion: undefined,
            hubConnection: null,
            executions: [],
            lastExecution: undefined,
            entityType: undefined,
            loading: false,
            mainStatus: null,
            options: undefined,
            isMappable: false,
        }
    }

    componentDidMount() {
        this.getTierBooleans();
        this.currentConversion();
        this.buttonLock();
        this.getExecutions();
        this.getOptions();

        const link = `${process.env.PUBLIC_URL}/executions`
        const hubConnection = new HubConnectionBuilder().withUrl(link).build();
        this.setState({ hubConnection }, () => {
            this.state.hubConnection!
                .start()
                .then(() => console.log('Connection started!'))
                .catch(err => console.log('Error while establishing connection :('));

            this.state.hubConnection!.on('ExecutionUpdated', (execution: ExecutionsModel) => {
                if ((execution.description === "Succeeded" || execution.description === "Completed") && execution.userId === this.props.currentUser!.id) {
                    store.dispatch(enqueueNotification({ type: 'success', message: execution.clientDescription + ' - ' + execution.conversionDescription + ' - ' + execution.title + ' - ' + execution.description }))
                }
                else if ((execution.description === "Failed" || execution.description === "Canceled" || execution.description === "Ended Unexpectedly" || execution.description === "Stopping") && execution.userId === this.props.currentUser!.id) {
                    store.dispatch(enqueueNotification({ type: 'error', message: execution.clientDescription + ' - ' + execution.conversionDescription + ' - ' + execution.title + ' - ' + execution.description }))
                }
                if (execution.packageName === '01-00-00-Transfer-Tier1.dtsx' || execution.packageName === '04-00-00-Transfer-Tier4.dtsx') {
                    this.copyButton(execution);
                    this.setState({ loading: false })
                }
            });

            this.state.hubConnection!.on('ExecutionCalled', (execution: ExecutionsModel) => {
                this.getExecutions();
            })

            this.state.hubConnection!.on('UpdateCountdown', (call: string) => {
                if (this.state.lastExecution && this.state.entityType) this.updateStatus(this.state.lastExecution, this.state.entityType);
            });
        })
    }

    componentDidUpdate(prevProps: TierSummaryPageProps) {
        const tierId = this.props.match.params.tierId;
        if (tierId !== prevProps.match.params.tierId) {
            this.setState({ buttonLock: true, loading: false, isMappable: false })
            this.getTierBooleans();
            this.buttonLock();
            this.getExecutions();
            this.mainStatus();
        }
    }

    componentWillUnmount() {
        this.state.hubConnection!.stop();
    }

    getExecutions = () => {
        this.executions!.listExecutions(this.props.match.params.conversionId)
            .then(response => {
                const executions = response!.reverse();
                this.setState({ executions }, () => {
                    const tierId = this.props.match.params.tierId;
                    if (tierId === 'tier0' || tierId === 'tier1' || tierId === 'tier4' || tierId === 'tier5') {
                        this.getStatisticsData();
                    } else {
                        this.processData(tierId);
                    }
                    this.mainStatus();
                })
            })
            .catch((error: any) => { })
    }

    getOptions = () => {
        this.options.get(this.props.match.params.conversionId)
            .then(values => {
                const options = values!.reduce(
                    (result, current) => ({ ...result, [current.id!]: current, }), {});
                this.setState({ options });
            })
            .catch(error => { });
    }

    getStatisticsData = () => {
        const conversionId = this.props.match.params.conversionId;
        const tier = this.props.match.params.tierId;
        this.statisticsClient.get(conversionId, tier)
            .then((response: any) => {
                let items = []
                if (tier === "tier4") response = response.concat(missingEntitiesTier4)
                if (tier === "tier5") response = response.concat(missingEntitiesTier5)
                let lastExecution = undefined;
                const executions = this.state.executions;
                for (let tierData of response) {
                    let packageName = "";
                    let sectionId = "";
                    if (migrateEntities.find(m => m.sectionId === tierData.entityType.replace(/\s/g, '').toLowerCase())) {
                        const migrateEntity = migrateEntities.find(m => m.sectionId === tierData.entityType.replace(/\s/g, '').toLowerCase())!;
                        packageName = migrateEntity.packageName!;
                        sectionId = migrateEntity.sectionId;
                    }
                    else if (!packageName && this.unMatchedEntities.includes(tierData.entityType)) {
                        packageName = '04-04-00-HomeownerDwellingFire.dtsx';
                        sectionId = 'hodf'
                        if (tierData.entityType.includes('Dwelling')) {
                            sectionId += "df"
                        } else {
                            sectionId += "ho"
                        }
                        if (tierData.entityType.includes('Coverage')) {
                            sectionId += "coveragecode"
                        } else {
                            sectionId += "formtype"
                        }
                    } else if (tierData.entityType === 'Commercial Property Cause Of Loss') {
                        sectionId = 'cpcauseofloss'
                    }
                    lastExecution = executions.find((e: any) => e.packageName === packageName)
                    if (lastExecution) {
                        this.setState({ lastExecution, entityType: tierData.entityType })
                    }
                    const status = lastExecution ? this.statusUpdate(lastExecution, tierData.entityType) : "";
 
                    let statuses = undefined;
                    if (tierData.entityType === 'Action Types') {
                        const lastExecutions: any[] = []
                        statuses = []
                        const actions = (this.state.conversion && this.state.conversion.imagePath !== '' && this.state.options && this.state.options["60b9bc6c-b2ed-4258-b4e9-4192cbce04cc"].value === '1') ? actionTypes : actionTypes.filter(a => a.sectionId !== 'images');
                        actions.forEach(at => lastExecutions.push({ execution: executions.find((e: any) => e.packageName === at.packageName), sectionId: at.sectionId }))
                        lastExecutions.forEach(le => statuses.push(this.statusUpdate(le.execution, le.sectionId.charAt(0).toUpperCase() + le.sectionId.slice(1))))
                    }

                    const total = [tierData.mapped, tierData.isDropped, tierData.notMapped].reduce((a, b) => a + b, 0)
                    const isMappable = total > 0

                    let item = { ...tierData, packageName, status, statuses, sectionId, tier, isMappable };
                    items.push(item);
                }
                this.setState({ items })
            })
            .catch((error: any) => { })
    }

    processData(tier: string) {
        const items = [];
        let tiersDataWithoutMigrations = tier === 'tier2' ? [...missingEntitiesTier2] : [...missingEntitiesTier3];
        for (let tierData of tiersDataWithoutMigrations) {
            let packageName = "";
            let sectionId = "";
            let lastExecution = undefined;
            if (migrateEntities.find(m => m.sectionId === tierData.entityType.replace(/\s/g, '').toLowerCase())) {
                const migrateEntity = migrateEntities.find(m => m.sectionId === tierData.entityType.replace(/\s/g, '').toLowerCase())!;
                packageName = migrateEntity.packageName!;
                sectionId = migrateEntity.sectionId;
            }
            const executions = this.state.executions;
            lastExecution = executions.find((e: any) => e.packageName === packageName)
            if (lastExecution) {
                this.setState({ lastExecution, entityType: tierData.entityType })
            }
            const status = lastExecution ? this.statusUpdate(lastExecution, tierData.entityType) : "";

            let item = { ...tierData, packageName, status, sectionId, tier };
            items.push(item);
        }
        this.setState({ items });
    }

    getTierBooleans = () => {
        this.executions.listExecutionsTierBoolean(this.props.match.params.conversionId)
            .then(response =>
                this.setState({ tierBooleans: response![0] })
            )
            .catch(error => { })
    }

    undoMigration = () => {
        const conversionId = this.props.match.params.conversionId
        const user = this.props.currentUser;
        const tierId = this.props.match.params.tierId
        const tierName = tierNames.get(tierId)
        if (user) {
            this.client.runUndoMigrationAction(conversionId, user.id!, tierId)
                .then(_ => {
                    sendNotification('success', 'Migration of ' + tierName + ' was reverted.');
                })
                .catch(error => { })
        }
    }

    migrateAll = () => {
        const conversionId = this.props.match.params.conversionId
        const user = this.props.currentUser;
        const tierId = this.props.match.params.tierId
        this.setState({ buttonLock: true, loading: true, mainStatus: null });
        if (user) {
            this.client.runMigrateAllAction(conversionId, user.id!, tierId)
                .then(_ => { })
                .catch(error => { })
        }
    }

    buttonLock = () => {
        const conversionId = this.props.match.params.conversionId;
        if (this.state.conversion && this.state.conversion!.dateCompleted === undefined) {
            this.executions.listExecutionsAllTierBoolean(conversionId)
                .then((response) => {
                    if (response) {
                        this.setState({ buttonLock: response.isLocked })
                    }
                    if (!this.state.conversion!.refreshRan) this.setState({ buttonLock: true })
                })
                .catch(error => { })
        }
    }

    copyMapping = (id: string) => {
        if (confirm('Are you sure, do you want to continue with Copy Mappings?')) {
            this.setState({ buttonLock: true, copyDisable: true });
            const userId = this.props.currentUser!.id ? this.props.currentUser!.id : '';
            this.client.copyMappings(this.props.match.params.conversionId, userId, `${id}Copy`)
                .then(() => {
                    this.setState({ copyDisable: true });
                    if (this.getStatisticsData) this.getStatisticsData();
                })
                .catch(error => { console.log(error) });
        }
    }

    copyButton = (lastCopy: any) => {
        if (lastCopy) {
            this.setState({ buttonLock: true, copyDisable: true });
            if (lastCopy.endTime) {
                this.setState({ buttonLock: false, copyDisable: false });
            } else {
                this.setState({ buttonLock: true, copyDisable: true });
            }
        }
    }

    toEntity = (item: any) => {
        this.props.history.push({
            pathname: `/conversion/${this.props.match.params.conversionId}/mapping/entity/${this.props.match.params.tierId}/${item.sectionId}/staging`,
            state: { tabName: 'stage' }
        });
    }

    currentConversion = () => {
        this.conversion.get(this.props.match.params.conversionId)
            .then(response =>
                this.setState({ conversion: response! }, () => {
                    this.buttonLock();
                })
            )
            .catch(error => { })
    }

    updateStatus(lastExecution: ExecutionsModel, entityType: string) {
        let items = this.state.items;
        for (let i = 0; i < items.length; i++) {
            if (items[i].entityType === entityType) {
                items[i].status = this.statusUpdate(lastExecution, entityType)
                this.mainStatus();
            }
        }
        this.setState({ items });
    }

    statusUpdate(lastExecution: ExecutionsModel, entityType: string) {
        if (lastExecution) {
            const dt = lastExecution!.endTime;
            if (dt) {
                const day = dt.getDate(),
                    month = dt.getMonth(),
                    year = dt.getFullYear(),
                    minutes = (dt.getMinutes() < 10 ? '0' : "") + dt.getMinutes();
                let hours = dt.getHours();

                const period = (hours < 12) ? 'AM' : 'PM';
                if (period === 'PM' && hours !== 12) hours = hours - 12;
                if (hours === 0) hours = 12;

                let color = ""
                const status = lastExecution.description
                if (status === "Canceled" || status === "Failed" || status === "Ended Unexpectedly") {
                    color = "danger"
                } else if (status === "Succeeded" || status === "Completed") {
                    color = "success"
                }
                return <h6> <Badge color={color}> {entityType} Completed:<br />{
                    `${(month + 1)}/${day}/${year} ${hours}:${minutes} ${period}`
                } <br />Status: {lastExecution.description} </Badge></h6>
            } else {
                const color = "primary"
                const now = new Date().valueOf();
                const estimate = lastExecution.estimatedFinish ? lastExecution.estimatedFinish!.valueOf() : 0;
                const timeLeft: string = estimate > now ? `${((estimate - now) / 60000).toFixed(2)} minutes` : 'Pending';
                return <h6> <Badge color={color}>Estimate: {timeLeft} <br />Status: {lastExecution.description} </Badge></h6>
            }

        }
    }

    mainStatus = () => {
        const packages = {
            tier1: {
                packageName: '00-00-01-00-Tier-Proc.dtsx', name: 'Tier 1'
            },
            tier2: {
                packageName: '00-00-02-00-Tier-Proc.dtsx', name: 'Tier 2'
            },
            tier3: {
                packageName: '00-00-03-00-Tier-Proc.dtsx', name: 'Tier 3'
            },
            tier4: {
                packageName: '00-00-04-00-Tier-Proc.dtsx', name: 'Tier 4'
            },
            tier5: {
                packageName: '00-00-00-PD-Prep.dtsx', name: 'Policy Details'
            }
        }
        const tierId = this.props.match.params.tierId;
        const tierPackage = (packages as any)[tierId]
        const execution = this.state.executions.filter(e => e.packageName === tierPackage.packageName)
        const mainStatus = execution[0] ? this.mainStatusUpdate(execution[0], tierPackage.name) : null

        this.setState({ mainStatus })
    }

    mainStatusUpdate(lastExecution: ExecutionsModel, entityType: string) {
        if (lastExecution) {
            const dt = lastExecution!.endTime;
            if (dt) {
                this.setState({ loading: false })
                const day = dt.getDate(),
                    month = dt.getMonth(),
                    year = dt.getFullYear(),
                    minutes = (dt.getMinutes() < 10 ? '0' : "") + dt.getMinutes();
                let hours = dt.getHours();

                const period = (hours < 12) ? 'AM' : 'PM';
                if (period === 'PM' && hours !== 12) hours = hours - 12;
                if (hours === 0) hours = 12;

                let color = ""
                const status = lastExecution.description
                if (status === "Canceled" || status === "Failed" || status === "Ended Unexpectedly") {
                    color = "danger"
                } else if (status === "Succeeded" || status === "Completed") {
                    color = "success"
                }
                return <h6> <Badge color={color}> {entityType} Completed: {
                    `${(month + 1)}/${day}/${year} ${hours}:${minutes} ${period}`
                } Status: {lastExecution.description} </Badge></h6>
            } else {
                this.setState({ loading: true })
                const color = "primary"
                const now = new Date().valueOf();
                const estimate = lastExecution.estimatedFinish ? lastExecution.estimatedFinish!.valueOf() : 0;
                const timeLeft: string = estimate > now ? `${((estimate - now) / 60000).toFixed(2)} minutes` : 'Pending';
                return <h6> <Badge color={color}>Estimate: {timeLeft} Status: {lastExecution.description} </Badge></h6>
            }

        }
    }

    isMappable = () => {
        store.dispatch(actionCreators.update())
    }


    public render() {
        const items = this.state.items.filter(i => {
            return (this.state.options && this.state.options["42a30566-b350-4e93-b0a1-d76919db2c6b"] && this.state.options["42a30566-b350-4e93-b0a1-d76919db2c6b"].value !== 'N' && i.entityType === 'Group') ||
                (this.state.options && this.state.options["61d5689e-56fe-4098-b964-7a797c540c10"] && this.state.options["61d5689e-56fe-4098-b964-7a797c540c10"].value !== 'N' && i.entityType === 'Branch')
                || (i.entityType !== 'Branch' && i.entityType !== 'Group')
        }).filter(i => {
            return !this.props.isMappable || (this.props.isMappable && i.isMappable)
        })
        const tierIndex = this.props.match.params.tierId;
        const tierName = tierNames.get(tierIndex)
        const tierBooleans: any = this.state!.tierBooleans;
        const tierBool = tierIndex !== 'tier1' && tierBooleans ? tierBooleans[tierIndex as any] : true;
        const tierMigrate = this.state.conversion && this.state.conversion!.imagePath !== "" && this.state.options && this.state.options["60b9bc6c-b2ed-4258-b4e9-4192cbce04cc"].value === '1' && tierName === 'Tier 4' ? `Migrate All Mapped ${tierName} with Images` : `Migrate All Mapped ${tierName}`;
        const mappable = !this.props.isMappable ? "Show All" : "Show Mappable"
        return (
            <>
                <h3 className='mapping-body-header'>{tierName} Entities</h3>
                <Toolbar>
                    <SecureContainer roles={[
                        Roles.vertaforeInternalRoleId,
                        Roles.developerAdministrationRoleId,
                        Roles.administrationRoleId
                    ]}>
                        <>
                                < Button className='load-button' color={!this.state.buttonLock && tierBool ? "primary" : "secondary"} onClick={this.migrateAll} disabled={!tierBool || this.state.buttonLock}>{tierMigrate}</Button>
                            {this.state.loading ? <img className='loading-indicator' alt="loading" src={'loader.gif'} /> : null}
                            {this.state.copyDisable && this.state.conversion && this.state.conversion.refreshRan ?
                                <img className='copy-loading-indicator' alt="loading" src={'loader.gif'} /> :
                                this.state.buttonLock || (this.state.conversion && this.state.conversion!.parentConversionId === null) || tierIndex === 'tier2' || tierIndex === 'tier3' ? null :
                                    <DataTableButton
                                        icon='scanner'
                                        id="MigrateAllCopy"
                                        title='Copy Mappings'
                                        action={() => this.copyMapping(tierIndex)} />}
                        </>
                    </SecureContainer>
                </Toolbar>
                <div className="header-display">
                    <h4 className='statistical-info-header'>Statistical Information</h4>
                    <div>
                        <div>{this.state.mainStatus}</div>
                    </div>
                </div>
                <DataTable>
                    <thead>
                        <tr>
                            <th className="number-column">Entity</th>
                            <th className="number-column">Mapped</th>
                            <th className="number-column">UnMapped</th>
                            <th className="number-column">Excluded</th>
                            <th className="text-column-cs">Status</th>
                            <th className="toggle-column"><label className="switch"><input type="checkbox" readOnly checked={this.props.isMappable} onClick={this.isMappable} /> <span className="slider round"></span></label>{mappable}</th>
                        </tr>
                    </thead>
                    <tbody className="summary">
                        {items.map((item, index) => (
                            <DataRow id={index.toString()} key={index} className={(item.mapped !== null && item.notMapped !== null) ? 'summaryLink' : ''} onClick={() => { if (item.mapped !== null && item.notMapped !== null) this.toEntity(item) }}>
                                <DataCell className="number-column">{item.entityType}</DataCell>
                                {item.mapped !== null && item.notMapped !== null && item.isMappable ?
                                    <>
                                        <DataCell className="number-column-cs">{item.mapped}</DataCell>
                                        <DataCell className="number-column-cs">{item.notMapped}</DataCell>
                                        <DataCell className="number-column-cs">{item.isDropped}</DataCell>
                                    </> :
                                    item.mapped !== null && item.notMapped !== null && !item.isMappable ?
                                        <>
                                            <DataCell className="number-column-cs summaryLink"></DataCell>
                                            <DataCell className="number-column-cs summaryLink">{'No Mapping Required'}</DataCell>
                                            <DataCell className="number-column-cs summaryLink"></DataCell>
                                        </> :
                                        <>
                                            <DataCell className="number-column-cs"></DataCell>
                                            <DataCell className="number-column-cs">{'No Source Data'}</DataCell>
                                            <DataCell className="number-column-cs"></DataCell>
                                        </>}
                                {item.statuses ?
                                    <DataCell className="text-column-cs">{item.statuses!.map((i: any) => <div>{i}</div>)}</DataCell> :
                                    <DataCell className="text-column-cs">
                                        {item.status}
                                    </DataCell>}
                                <DataCell className="toggle-column-cs"></DataCell>
                            </DataRow>
                        ))}

                    </tbody>
                </DataTable>
            </>
        )
    }
}

export default connect(
    (state: ApplicationState) => {
        return {
            currentUser: state.authentication && state.authentication.currentUser,
            isMappable: state.boolean && state.boolean.isMappable,
        }
    })(TierSummaryPage);

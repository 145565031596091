// The lowercased entity names as they appear in a URL.
export const entityNames = new Map<string, string>([
    ['employees', 'Employees'],
    ['company', 'Companies'],
    ['vendor', 'Vendor'],
    ['brokers', 'Brokers'],
    ['lineofbusiness', 'Line of Business'],
    ['businessunits', 'Business Units'],
    ['division', 'Division'],
    ['branch', 'Branch'],
    ['activitysuspenseactiontypes', 'Activity Suspense Action Types'],
    ['department', 'Department'],
    ['group', 'Group'],
    ['clients', 'Clients'],
    ['clientcontacts', 'Client Contacts'],
    ['companycontact', 'Company Contact'],
    ['actiontypes', 'Action Types'],
    ['certificateholder', 'Certificate Holder'],
    ['policyheaders', 'Policy Headers'],
    ['policylobdetails', 'Policy LOB Details'],
    ['serviceagreements', 'Service Agreements'],
    ['submissions', 'Submissions'],
    ['kindofloss', 'Kind of Loss'],
    ['claimtype', 'Claim Type'],
    ['activities', 'Activities'],
    ['images', 'Images'],
    ['notes', 'Notes'],
    ['executedetails', 'Execute Details'],
    ['personalautoantilockbrakes', 'Personal Auto Antilock Brakes'],
    ['personalautobodytype', 'Personal Auto Body Type'],
    ['personalautocoveragecodes', 'Personal Auto Coverage Codes'],
    ['hodfdfformtype', 'Dwelling Fire Form Type'],
    ['hodfdfcoveragecode', 'Dwelling Fire Coverage Codes'],
    ['hodfhoformtype', 'Homeowner Form Type'],
    ['hodfhocoveragecode', 'Homeowner Coverage Codes'],
    ['commercialauto', 'Commercial Auto'],
    ['workerscomp', 'Workers Comp'],
    ['commercialumbrella', 'Commercial Umbrella'],
    ['cpcauseofloss', 'Commercial Property Cause Of Loss'],
    ['equipmentfloater', 'Equipment Floater'],
    ['generalliability', 'General Liability'],
    ['personalumbrella', 'Personal Umbrella'],
    ['commercialapplication', 'Commercial Application'],
    ['claimpayments', 'Claim Payments'],
    ['employeetypes', 'Employee Type'],
    ['policystatustypes', 'Policy Status Type'],
    ['policytransactiontypes', 'Policy Transaction Type'],
    ['businessownerpolicies', 'Business Owner Policies'],
    //merge tool
    ['divisionmerge', 'Divisions'],
    ['branchmerge', 'Branches'],
    ['departmentmerge', 'Departments'],
    ['groupmerge', 'Groups'],
    ['acquisitioncodes', 'Acquisition Codes'],
    ['attachedforms', 'Attached Forms'],
    ['activitysuspenseactiontypes', 'Activity Suspense Action Types'],
    ['billingtransactions', 'Billing Transactions'],
    ['bodytypes', 'Body Types'],
    ['businesstypes', 'Business Types'],
    ['businessorigins', 'Business Origins'],
    ['brokersmerge','Brokers'],
    //['chargecategories', 'Charge Categories'],
    ['claimpaidbytypes', 'Claim Paid By Types'],
    ['claimpaymenttypes', 'Claim Payment Types'],
    ['companymerge', 'Companies'],
    ['companypersonnelpositions', 'Company Personnel Positions'],
    ['companyplans', 'Company Plans'],
    ['constructiontypes', 'Construction Types'],
    ['contacttypes', 'Contact Types'],
    ['coveragecodes', 'Coverage Codes'],
    ['coverageoptions', 'Coverage Options'],
    ['customernotations', 'Customer Notations'],
    //['dataporterfiles', 'Data Porter Files'],
    ['documentclassifications', 'Document Classifications'],
    ['documentcategories', 'Document Categories'],
    ['documenttypes', 'Document Types'],
    //['downloadtransactiontypes', 'Dowload Transactions Titles'],
    ['employeemerge', 'Employees'],
    ['heattypes', 'Heat Types'],
    ['incomegroups', 'Income Groups'],
    ['interesttypes', 'Interest Types'],
    ['jobtitles', 'Job Titles'],
    ['kindoflossmerge', 'Kinds of Loss'],
    ['lineofbusinessmerge','Line of Business'],
    ['occupations', 'Occupations'],
    ['paymentplans', 'Payment Plans'],
    ['personalpropertyclasses', 'Personal Property Classes'],
    ['policysubnotations','Policy Sub Notations'],
    ['profiles', 'Profile Questions'],
    ['relationships', 'Relationships'],
    ['subjectsofinsurance', 'Subjects of Insurance'],
    ['textsetups','Text Setups'],
    //['transactiontypes', 'Transaction Titles'],
    ['typeentities', 'Type Entities'],
    ['unlicensedexcludeddrivers', 'Unlicensed Excluded Drivers'],
    ['violations', 'Violations'],
    ['x-refs','X-Refs']
]);

export const tierIds = {
    tier1: 'tier1',
    tier2: 'tier2',
    tier3: 'tier3',
    tier4: 'tier4',
    tier5: 'tier5'
}

export const tierNames = new Map<string, string>([
    [tierIds.tier1, 'Tier 1'],
    [tierIds.tier2, 'Tier 2'],
    [tierIds.tier3, 'Tier 3'],
    [tierIds.tier4, 'Tier 4'],
    [tierIds.tier5, 'Policy Details']
]);

export type TabName = 'source' | 'staging' | 'target'|'imageLog';

export const defaultMappingTabs: TabName[] = ['source', 'staging', 'target']

// The lowercased entity names mapped to the names of tabs available
// for these entities. If not specified, defaultMappingTabs are taken.
export const mappingTabs = new Map<string, TabName[]>([
    ['actiontypes', ['staging', 'imageLog']],
    ['claimtype', ['staging']],
    ['employeetypes', ['source', 'staging']],
    ['group', ['staging', 'target']],
    ['notes', ['staging']],
    ['kindofloss', ['staging']],
    ['claimtype', ['staging']],
    ['personalautobodytype', ['staging']],
    ['personalautoantilockbrakes', ['staging']],
    ['personalautocoveragecodes', ['staging']],
    ['hodfdfformtype', ['staging']],
    ['hodfdfcoveragecode', ['staging']],
    ['hodfhoformtype', ['staging']],
    ['hodfhocoveragecode', ['staging']],
    ['policystatustypes', ['source', 'staging']],
    ['policytransactiontypes', ['source', 'staging']],
    ['commercialauto', ['staging']],
    ['workerscomp', ['staging']],
    ['commercialumbrella', ['staging']],
    ['cpcauseofloss', ['staging']],
    ['equipmentfloater', ['staging']],
    ['generalliability', ['staging']],
    ['personalumbrella', ['staging']],
    ['commercialapplication', ['staging']],
    ['companycontact', ['source', 'target']],
    ['certificateholder', ['source', 'target']],
    ['businessownerpolicies', ['staging']],
    //merge tool
    ['divisionmerge', ['staging']],
    ['branchmerge', ['staging']],
    ['departmentmerge', ['staging']],
    ['groupmerge', ['staging']],
    ['acquisitioncodes', ['staging']],
    ['attachedforms', ['staging']],
    ['activitysuspenseactiontypes', ['staging']],
    ['billingtransactions', ['staging']],
    ['bodytypes', ['staging']],
    ['businesstypes', ['staging']],
    ['businessorigins', ['staging']],
    ['brokersmerge', ['staging']],
    //['chargecategories', ['staging']],
    ['claimpaidbytypes', ['staging']],
    ['claimpaymenttypes', ['staging']],
    ['companymerge', ['staging']],
    ['companyplans', ['staging']],
    ['companypersonnelpositions', ['staging']],
    ['constructiontypes', ['staging']],
    ['contacttypes', ['staging']],
    ['coveragecodes', ['staging']],
    ['coverageoptions', ['staging']],
    ['customernotations', ['staging']],
    //['dataporterfiles', ['staging']],
    ['documentclassifications',['staging']],
    ['documentcategories', ['staging']],
    ['documenttypes', ['staging']],
    //['downloadtransactiontypes', ['staging']],
    ['employeemerge', ['staging']],
    ['heattypes', ['staging']],
    ['incomegroups', ['staging']],
    ['interesttypes', ['staging']],
    ['jobtitles', ['staging']],
    ['lineofbusinessmerge', ['staging']],
    ['kindoflossmerge', ['staging']],
    ['occupations', ['staging']],
    ['paymentplans', ['staging']],
    ['personalpropertyclasses', ['staging']],
    ['policysubnotations', ['staging']],
    ['profiles', ['staging']],
    ['relationships', ['staging']],
    ['subjectsofinsurance', ['staging']],
    ['textsetups', ['staging']],
    //['transactiontypes', ['staging']],
    ['typeentities', ['staging']],
    ['unlicensedexcludeddrivers', ['staging']],
    ['violations', ['staging']],
    ['x-refs', ['staging']]
]);


export const migrateEntities = [
    {
        sectionId: 'employeetypes',
        packageName: '',
        copy: '00-01-01-EmployeeTypes.dtsx',
        tier: 'tier0',
    },
    {
        sectionId: 'employees',
        packageName: '01-01-00-02-Employee-Extract-Load.dtsx',
        copy: '01-01-01-Employee.dtsx',
        tier: 'tier1',
        auto: '01-01-02-02-Employee-Map-EmployeeType.dtsx',
    },
    {
        sectionId: 'company',
        packageName: '01-02-00-02-Company-Extract-Load.dtsx',
        copy: '01-02-01-Company.dtsx',
        tier: 'tier1'
    },
    {
        sectionId: 'branch',
        packageName: '01-06-00-02-BU-Branch-Extract-Load.dtsx',
        copy: '01-06-01-BU-Branch.dtsx',
        tier: 'tier1'
    },
    {
        sectionId: 'brokers',
        packageName: '01-04-00-02-Broker-Extract-Load.dtsx',
        copy: '01-04-01-Broker.dtsx',
        tier: 'tier1'
    },
    {
        sectionId: 'department',
        packageName: '01-07-00-02-BU-Department-Extract-Load.dtsx',
        copy: '01-07-01-Department.dtsx',
        tier: 'tier1'
    },
    {
        sectionId: 'division',
        packageName: '01-05-00-02-BU-Division-Extract-Load.dtsx',
        copy: '01-05-01-BU-Division.dtsx',
        tier: 'tier1'
    },
    {
        sectionId: 'group',
        packageName: '01-08-00-02-BU-Group-Extract-Load.dtsx',
        copy: '01-08-01-BU-Group.dtsx',
        tier: 'tier1'
    },
    {
        sectionId: 'vendor',
        packageName: '01-03-00-02-Vendor-Extract-Load.dtsx',
        copy: '01-03-01-Vendor.dtsx',
        tier: 'tier1'
    },
    {
        sectionId: 'lineofbusiness',
        packageName: '',
        copy: '01-09-01-LOB.dtsx',
        tier: 'tier1'
    },
    {
        sectionId: 'policytransactiontypes',
        packageName: '',
        copy: '01-13-01-Policy-Transaction-Types.dtsx',
        tier: 'tier1'
    },
    {
        sectionId: 'policystatustypes',
        packageName: '',
        copy: '01-14-01-Policy-Status-Types.dtsx',
        tier: 'tier1'
    },
    {
        sectionId: 'actiontypes',
        packageName: '',
        copy: '03-19-01-Action-Types.dtsx',
        tier: 'tier4'
    },
    {
        sectionId: 'clients',
        packageName: '02-11-00-02-Client-Extract-Load.dtsx',
        tier: 'tier2'
    },
    {
        sectionId: 'policyheaders',
        packageName: '03-16-00-02-Policy-Extract-Load.dtsx',
        tier: 'tier3'
    },
    {
        sectionId: 'clientcontacts',
        packageName: '03-15-00-02-ClientContact-Extract-Load.dtsx',
        tier: 'tier3'
    },
    {
        sectionId: 'kindofloss',
        packageName: '04-22-00-02-Claim-Extract-Load.dtsx',
        copy: "03-20-01-ClaimKindOfLoss.dtsx",
        tier: 'tier4'
    },
    {
        sectionId: 'notes',
        packageName: '04-25-00-02-Note-Extract-Load.dtsx',
        tier: 'tier4'
    },
    {
        sectionId: 'personalautoantilockbrakes',
        packageName: '04-03-00-PersonalAuto.dtsx',
        copy: '04-28-01-PA-Anti-Lock.dtsx',
        tier: 'tier5'
    },
    {
        sectionId: 'personalautobodytype',
        packageName: '04-03-00-PersonalAuto.dtsx',
        copy: '04-27-01-PA-Body-Type.dtsx',
        tier: 'tier5'
    },
    {
        sectionId: 'personalautocoveragecodes',
        packageName: '04-03-00-PersonalAuto.dtsx',
        copy: '04-29-01-PA-Coverage-Code.dtsx',
        tier: 'tier5'
    },
    {
        sectionId: 'hodfdfformtype',
        packageName: '04-04-00-HomeownerDwellingFire.dtsx',
        copy: '04-30-01-DF-Form-Type.dtsx',
        tier: 'tier5'
    },
    {
        sectionId: 'hodfdfcoveragecode',
        packageName: '04-04-00-HomeownerDwellingFire.dtsx',
        copy: '04-31-01-DF-Coverage-Code.dtsx',
        tier: 'tier5'
    },
    {
        sectionId: 'hodfhoformtype',
        packageName: '04-04-00-HomeownerDwellingFire.dtsx',
        copy: '04-32-01-HO-Form-Type.dtsx',
        tier: 'tier5'
    },
    {
        sectionId: 'hodfhocoveragecode',
        packageName: '04-04-00-HomeownerDwellingFire.dtsx',
        copy: '04-33-01-HO-Coverage-Code.dtsx',
        tier: 'tier5'
    },
    {
        sectionId: 'commercialauto',
        packageName: '04-09-00-BusinessAuto.dtsx',
        tier: 'tier5'
    },
    {
        sectionId: 'workerscomp',
        packageName: '04-05-00-WorkersComp.dtsx',
        tier: 'tier5'
    },
    {
        sectionId: 'commercialumbrella',
        packageName: '04-06-00-CommercialUmbrella.dtsx',
        tier: 'tier5'
    },
    {
        sectionId: 'cpcauseofloss',
        packageName: '04-02-00-Property.dtsx',
        copy: '04-34-01-CP-CauseOfLoss.dtsx',
        tier: 'tier5'
    },
    {
        sectionId: 'equipmentfloater',
        packageName: '04-07-00-EquipmentFloater.dtsx',
        tier: 'tier5'
    },
    {
        sectionId: 'generalliability',
        packageName: '04-10-00-GeneralLiability.dtsx',
        tier: 'tier5'
    },
    {
        sectionId: 'personalumbrella',
        packageName: '04-08-00-PersonalUmbrella.dtsx',
        tier: 'tier5'
    },
    {
        sectionId: 'commercialapplication',
        packageName: '04-01-00-CommercialLocations.dtsx',
        tier: 'tier5'
    },
    {
        sectionId: 'claimpayments',
        packageName: '05-34-00-02-ClaimPayment-Extract-Load.dtsx',
        tier: 'tier5'
    },
    {
        sectionId: 'businessownerpolicies',
        packageName: '04-11-00-BOP.dtsx',
        tier: 'tier5'
    },
    { sectionId: 'divisionmerge', copy: 'Merge-01-Divisions.dtsx'},
    { sectionId: 'branchmerge', copy: 'Merge-02-Branches.dtsx'},
    { sectionId: 'departmentmerge', copy: 'Merge-03-Departments.dtsx'},
    { sectionId: 'groupmerge', copy: 'Merge-04-Groups.dtsx'},
    { sectionId: 'acquisitioncodes', copy: 'Merge-45-AcquisitionCodes.dtsx'},
    { sectionId: 'attachedforms', copy: 'Merge-05-AttachedForm.dtsx'},
    { sectionId: 'activitysuspenseactiontypes', copy: 'Merge-27-ActivitySuspenseActionTypes.dtsx' },
    { sectionId: 'billingtransactions', copy: 'Merge-50-BillingTransactions.dtsx' },
    { sectionId: 'bodytypes', copy: 'Merge-06-BodyTypes.dtsx'},
    { sectionId: 'businesstypes', copy: 'Merge-25-BusinessTypes.dtsx'},
    { sectionId: 'businessorigins', copy: 'Merge-46-BusinessOrigins.dtsx'},
    { sectionId: 'brokersmerge', copy: 'Merge-30-Brokers.dtsx'},
    { sectionId: 'claimpaidbytypes', copy: 'Merge-43-ClaimPaidBytypes.dtsx'},
    { sectionId: 'claimpaymenttypes', copy: 'Merge-18-ClaimPaymentTypes.dtsx'},
    { sectionId: 'companymerge', copy: 'Merge-31-Companies.dtsx'},
    { sectionId: 'companyplans', copy: 'Merge-36-CompanyPlans.dtsx'},
    { sectionId: 'companypersonnelpositions', copy: 'Merge-44-CompanyPersonnelPositions.dtsx'},
    { sectionId: 'constructiontypes', copy: 'Merge-08-ConstructionTypes.dtsx'},
    { sectionId: 'contacttypes', copy: 'Merge-09-ContactTypes.dtsx'},
    { sectionId: 'coveragecodes', copy: 'Merge-42-CoverageCodes.dtsx'},
    { sectionId: 'coverageoptions', copy: 'Merge-10-CoverageOptions.dtsx'},
    { sectionId: 'customernotations', copy: 'Merge-32-CustomerNotations.dtsx'},
    { sectionId: 'documentclassifications', copy: 'Merge-41-DocumentClassifications.dtsx'},
    { sectionId: 'documentcategories', copy: 'Merge-47-DocumentCategories.dtsx'},
    { sectionId: 'documenttypes', copy: 'Merge-48-DocumentTypes.dtsx'},
    { sectionId: 'employeemerge', copy: 'Merge-33-Employees.dtsx'},
    { sectionId: 'heattypes', copy: 'Merge-12-HeatTypes.dtsx'},
    { sectionId: 'interesttypes', copy: 'Merge-14-InterestTypes.dtsx'},
    { sectionId: 'jobtitles', copy: 'Merge-15-JobTitles.dtsx'},
    { sectionId: 'lineofbusinessmerge', copy: 'Merge-35-LineOfBusiness.dtsx'},
    { sectionId: 'kindoflossmerge', copy: 'Merge-16-KindsOfLoss.dtsx'},
    { sectionId: 'occupations', copy: 'Merge-17-Occupations.dtsx' },
    { sectionId: 'paymentplans', copy: 'Merge-51-PaymentPlans.dtsx' },
    { sectionId: 'personalpropertyclasses', copy: 'Merge-49-PersonalPropertyClasses.dtsx'},
    { sectionId: 'policysubnotations', copy: 'Merge-37-PolicySubnotations.dtsx'},
    { sectionId: 'profiles', copy: 'Merge-38-ProfileQuestions.dtsx'},
    { sectionId: 'relationships', copy: 'Merge-20-Relationships.dtsx'},
    { sectionId: 'subjectsofinsurance', copy: 'Merge-21-SubjectsOfInsurance.dtsx'},
    { sectionId: 'textsetups', copy: '.dtsx'},
    { sectionId: 'transactiontypes', copy: '.dtsx'},
    { sectionId: 'typeentities', copy: 'Merge-24-TypeEntities.dtsx'},
    { sectionId: 'unlicensedexcludeddrivers', copy: 'Merge-26-UnlicensedExcludedDrivers.dtsx'},
    { sectionId: 'violations', copy: 'Merge-19-Violations.dtsx'},
    { sectionId: 'x-refs', copy:'Merge-40-X-Refs.dtsx'}
]

export const missingEntitiesTier2 = [
    {
        entityType: "Clients",
        mapped: 0,
        notMapped: 0,
        isDropped: 0,
    }
];

export const missingEntitiesTier3 = [
    {
        entityType: "Policy Headers",
        mapped: 0,
        notMapped: 0,
        isDropped: 0,
    },
    {
        entityType: "Client Contacts",
        mapped: 0,
        notMapped: 0,
        isDropped: 0,
    }
];
export const missingEntitiesTier4 = [
    {
        entityType: "Notes",
        mapped: 0,
        notMapped: 0,
        isDropped: 0,
    }
];

export const missingEntitiesTier5 = [
    {
        entityType: "Commercial Auto",
        mapped: 0,
        notMapped: 0,
        isDropped: 0,
    },
    {
        entityType: "Workers Comp",
        mapped: 0,
        notMapped: 0,
        isDropped: 0,
    },
    {
        entityType: "Commercial Umbrella",
        mapped: 0,
        notMapped: 0,
        isDropped: 0,
    },
    {
        entityType: "Equipment Floater",
        mapped: 0,
        notMapped: 0,
        isDropped: 0,
    },
    {
        entityType: "General Liability",
        mapped: 0,
        notMapped: 0,
        isDropped: 0,
    },
    {
        entityType: "Personal Umbrella",
        mapped: 0,
        notMapped: 0,
        isDropped: 0,
    },
    {
        entityType: "Commercial Application",
        mapped: 0,
        notMapped: 0,
        isDropped: 0,
    },
    {
        entityType: "Business Owner Policies",
        mapped: 0,
        notMapped: 0,
        isDropped: 0,
    },
]

export const actionTypes = [
    {
        sectionId: 'activities',
        packageName: '04-23-00-02-Activity-Extract-Load.dtsx'
    },
    {
        sectionId: 'images',
        packageName: '04-24-00-02-Image-Extract-Load.dtsx'
    },
    {
        sectionId: 'suspense',
        packageName: '04-26-00-02-Suspense-Extract-Load.dtsx'
    },
]

export const mergeTool = [
    { stage: 'divisionmerge', length: 6, addToMain: true, code: false},
    { stage: 'branchmerge', length: 6, addToMain: true, code: false},
    { stage: 'departmentmerge', length: 6, addToMain: true, code: false},
    { stage: 'groupmerge', length: 6, addToMain: true, code: false},
    {stage: 'acquisitioncodes', length: 5, addToMain: true, code: true},
    {stage: 'attachedforms', length: 5, addToMain: true, code: true},
    { stage: 'activitysuspenseactiontypes', length: 35, addToMain: true, code: false },
    {stage: 'billingtransactions', length: 35, addToMain: false, code: true },
    {stage: 'bodytypes', length: 5, addToMain: true, code: true},
    {stage: 'businesstypes', length: 5, addToMain: true, code: true},
    {stage: 'businessorigins', length: 2, addToMain: true, code: true},
    { stage: 'brokersmerge', length: 6, addToMain: true, code: false},
    {stage: 'claimpaidbytypes', length: 5, addToMain: true, code: true},
    {stage: 'claimpaymenttypes', length: 5, addToMain: true, code: true},
    { stage: 'companymerge', length: 6, addToMain: true, code: false},
    {stage: 'companyplans', length: 5, addToMain: true, code: true},
    {stage: 'companypersonnelpositions', length: 5, addToMain: true, code: true},
    {stage: 'constructiontypes', length: 5, addToMain: true, code: true},
    {stage: 'contacttypes', length: 2, addToMain: true, code: true},
    { stage: 'coveragecodes', length: 5, addToMain: true, code: false},
    {stage: 'coverageoptions', length: 2, addToMain: true, code: true},
    { stage: 'customernotations', length: 35, addToMain: true, code: false},
    { stage: 'documentclassifications', length: 50, addToMain: true, code: false},
    {stage: 'documentcategories', length: 2, addToMain: true, code: true},
    {stage: 'documenttypes', length: 5, addToMain: true, code: true},
    { stage: 'employeemerge', length: 6, addToMain: true, code: false},
    {stage: 'heattypes', length: 5, addToMain: true, code: true},
    {stage: 'interesttypes', length: 2, addToMain: true, code: true},
    {stage: 'jobtitles', length: 5, addToMain: true, code: true},
    {stage: 'lineofbusinessmerge', length: 5, addToMain: true, code: true},
    {stage: 'kindoflossmerge', length: 5, addToMain: true, code: true},
    { stage: 'occupations', length: 2, addToMain: true, code: true },
    { stage: 'paymentplans', length: 3, addToMain: false, code: true },
    {stage: 'personalpropertyclasses', length: 2, addToMain: true, code: true},
    { stage: 'policysubnotations', length: 35, addToMain: true, code: false},
    { stage: 'profiles', length: 6, addToMain: false, code: false},
    {stage: 'relationships', length: 5, addToMain: true, code: true},
    {stage: 'subjectsofinsurance', length: 5, addToMain: true, code: true},
    { stage: 'textsetups', length: 35, addToMain: false, code: false},
    { stage: 'transactiontypes', length: 6, addToMain: true, code: false},
    {stage: 'typeentities', length: 2, addToMain: true, code: true},
    {stage: 'unlicensedexcludeddrivers', length: 1, addToMain: true, code: true},
    {stage: 'violations', length: 5, addToMain: true, code: true},
    { stage: 'x-refs', length: 35, addToMain: true, code: false}
]

export const regex = {
    standard : /[^A-Za-z0-9_,'!~ \$\(\)@./\\:#&+-]/gi,
    standard360 : /[^A-Za-z0-9\"_, '!~ &%*-:;<>=?{}|\[\]^\$\(\)@./\\:#&+-]/gi,
    alphaNumeric: /[^a-z0-9]/gi,
    paths: /[^a-zA-Z0-9]:\\[\\\S|*\S]/gi,
    alpha: /[^A-Za-z]/gi,
}
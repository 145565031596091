import * as React from 'react';
import { utilities } from 'services/Utilities';

interface IFooterProps { }

type FooterPageState = {
    version: string,
};

class Footer extends React.Component<IFooterProps, FooterPageState>{
    constructor(props: Readonly<IFooterProps>) {
        super(props);
        this.state = {
            version: ''
        };
    }

    componentDidMount() {
        this.getVariable();
    }

    private getVariable = () => {
        utilities.getVariable("Version")
            .then((version) => {
                this.setState({ version })
            })
            .catch(error => {
                console.log(error)
            })
    }

    public render() {
        const d = new Date();
        let year = d.getFullYear();
        const index = this.state.version.indexOf('/')
        const release = index > -1 ? ` Release - ${this.state.version.substr(index + 1)}` : ''
        return (
            <footer>
                <div>{`\u00a9 ${year} `}<b>Vertafore, Inc.</b> All rights reserved.{release}</div>
            </footer>
        )
    }
}

export default (Footer);

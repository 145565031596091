import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { ApplicationState } from 'store';

import { ConversionAdministrationPopup, ConversionAdministrationPopupOnCloseEvent } from './Administration';

import { SecureContainer } from 'components/basic/Authentication';
import { ToolbarLinkButton } from 'components/basic/Button';
import { DataTable, DataRow, DataCell, DataTableButton } from 'components/basic/DataTable';
import Toolbar from 'components/basic/Toolbar';

import * as Clients from '../../client/ApiClient';
import * as Roles from 'constants/Roles';
import * as SourceSystem from 'constants/SourceSystem';
import { Button, Input, InputGroupAddon, Tooltip } from 'reactstrap';

type ConversionListProps = RouteComponentProps & {
    currentUser: Clients.IUserLoginDetailsModel | undefined,
};

interface ConversionListPageState {
    conversions: Clients.ConversionModel[];
    clientsList: Clients.IConversionClientModel[];
    conversionTypes: Clients.ConversionTypeModel[];
    conversionTypeSelected: string;
    popupIsOpen: boolean,
    popupConversionId?: string,
    popupConversionType?: string,
    sort: boolean,
    isActive: boolean,
    filter: string,
    tooltipColumn: string,
}

class ConversionListPage extends React.Component<ConversionListProps, ConversionListPageState>{
    private readonly client: Clients.ConversionClient = new Clients.ConversionClient();
    private readonly clientClient: Clients.ClientClient = new Clients.ClientClient();
    private readonly conversionTypeClient: Clients.ConversionTypeClient = new Clients.ConversionTypeClient();

    constructor(props: ConversionListProps) {
        super(props)
        this.state = {
            conversions: [],
            clientsList: [],
            conversionTypes: [],
            conversionTypeSelected: '',
            popupIsOpen: false,
            popupConversionId: undefined,
            popupConversionType: undefined,
            sort: true,
            isActive: false,
            filter: '',
            tooltipColumn: '',
        }
    }

    componentDidMount() {
        this.refreshConversions();
        this.conversionTypes();
    }

    private refreshConversions = () => {
        this.client.getAll()
            .then(response => {
                if (response != null) {
                    this.setState({
                        conversions: response,
                    })
                }
            })
            .catch(error => { })

        this.clientClient.getAll()
            .then((response) => {
                if (response !== null) {
                    if (response.length < 1) throw new Error('Clients list should not be empty')

                    this.setState({
                        clientsList: response
                    })
                }
            })
            .catch(error => { })
    }

    private conversionTypes = () => {
        this.conversionTypeClient.getAll()
            .then(response => {
                if (response != null) {
                    this.setState({
                        conversionTypes: response,
                    })
                }
            })
            .catch(error => { })
    }

    handleChange = (e: any) => {
        const conversionTypeSelected = e.target.value
        this.setState({ conversionTypeSelected })
    }

    private handleCloseAdministrationPopup = (ev: ConversionAdministrationPopupOnCloseEvent) => {
        this.setState({
            popupIsOpen: false,
        });
        if (ev.refresh) {
            this.refreshConversions();
        }
    }

    sort(column: string, column2: string) {
        const conversions = this.state.conversions.sort((a: any, b: any) => {
            let x = column2 === '' ? a[column] : a[column][column2]
            let y = column2 === '' ? b[column] : b[column][column2]
            if (typeof x === 'string' && typeof y === 'string') {
                x = x.toLowerCase().trim()
                y = y.toLowerCase().trim()
            }
            //NULL is first.  This is allowing active converison to display first with DateCompelte
            if (column === 'dateCompleted') {
                if (x === null || x === undefined || x === '') {
                    return -1;
                }
                else if (y === null || y === undefined || y === '') {
                    return 1;
                } else if (x === y) {
                    return 0;
                }
            }
            if (this.state.sort) {
                return x < y ? -1 : 1;
            }
            else {
                return x < y ? 1 : -1;
            }
        })
        this.setState({ sort: !this.state.sort, conversions });
    }

    isActive = () => {
        const isActive = !this.state.isActive
        this.setState({ isActive })
    }

    handleSearch = (e: any) => {
        let filter = e.target.value
        this.setState({ filter })
    }

    clearFilter = () => {
        let filter = '';
        this.setState({ filter })
    }

    toggle = (index: number, e: any) => {
        const tooltipColumn = this.state.tooltipColumn === e.target.id ? '' : e.target.id;
        this.setState({ tooltipColumn })
    };

    public render() {
        const conversions = this.state.conversions
            .filter(c => c.dateCompleted === undefined || !this.state.isActive)
            .filter(c => c.conversionTypeId === this.state.conversionTypeSelected || this.state.conversionTypeSelected === '')
            .filter(item =>
                (item.description && item.description.toLowerCase().includes(this.state.filter.trim().toLowerCase()))
                || (item.source!.catalog && item.source!.catalog.toLowerCase().includes(this.state.filter.trim().toLowerCase()))
                || (item.target!.catalog && item.target!.catalog.toLowerCase().includes(this.state.filter.trim().toLowerCase()))
                || (item.client!.description && item.client!.description.toLowerCase().includes(this.state.filter.trim().toLowerCase()))
                || (item.userCreated && item.userCreated.toLowerCase().includes(this.state.filter.trim().toLowerCase())))
        const enableMiniConv: boolean = (this.state.clientsList.length > 0 && this.state.clientsList[0].isMiniConvEnabled) ? true : false;
        const rolesMiniConv: any = [
            enableMiniConv ? Roles.customerAdministratorRoleId : [],
            enableMiniConv ? Roles.customerUserRoleId : []
        ];
        return (
            <>
                <Toolbar>
                    <select className="custom-select conversionType-select" onChange={this.handleChange}>
                        <option value=''>Conversion Types</option>
                        {this.state.conversionTypes
                            .filter(c => c.isActive || c.description!.includes('Mini'))
                            .map((client) => (
                                <option
                                    key={client.id}
                                    value={client.id}
                                >
                                    {client.description}
                                </option>
                            ))}
                    </select>
                    <Input placeholder='Search'
                        name='filter'
                        onChange={this.handleSearch}
                        value={this.state.filter}
                        className='search'
                    />
                    <InputGroupAddon addonType='append' className='material-icon'>
                        <Button onClick={this.clearFilter}> close</Button>
                    </InputGroupAddon>
                    <SecureContainer roles={rolesMiniConv}>
                        <ToolbarLinkButton type='primary' to='/conversion/new'>Create a mini conversion</ToolbarLinkButton>
                    </SecureContainer>
                    <SecureContainer roles={[Roles.administrationRoleId, Roles.developerAdministrationRoleId, Roles.vertaforeInternalRoleId, Roles.noAccessRoleId]}>
                        <ToolbarLinkButton type='primary' to='/conversion/new'>Create a new conversion</ToolbarLinkButton>
                    </SecureContainer>
                </Toolbar>
                <DataTable>
                    <thead>
                        <tr className='nonFullWidthRow'>
                            <th className="number-column">#</th>
                            <th onClick={() => this.sort('description', '')}>Project Name<i className="fa fa-sort"></i></th>
                            <th onClick={() => this.sort('conversionTypeDescription', '')}>Conversion Type<i className="fa fa-sort"></i></th>
                            <th onClick={() => this.sort('source', 'catalog')}>Source<i className="fa fa-sort"></i></th>
                            <th onClick={() => this.sort('target', 'catalog')}>Destination<i className="fa fa-sort"></i></th>
                            <th onClick={() => this.sort('client', 'description')}>Customer Name<i className="fa fa-sort"></i></th>
                            <th onClick={() => this.sort('createdDate', '')}>Date Created<i className="fa fa-sort"></i></th>
                            <th onClick={() => this.sort('userCreated', '')}>Created By<i className="fa fa-sort"></i></th>
                            {/*<th onClick={() => this.sort('lastEditedDate', '')}>Date Edited<i className="fa fa-sort"></i></th>*/}
                            {/*<th onClick={() => this.sort('userEdited', '')}>Edited By<i className="fa fa-sort"></i></th>*/}
                            <th onClick={() => this.sort('dateCompleted', '')}>Date Completed<i className="fa fa-sort"></i></th>
                            <th><label className="switch"><input type="checkbox" readOnly checked={this.state.isActive} onClick={this.isActive} /> <span className="slider round"></span></label>Active</th>
                        </tr>
                    </thead>
                    <tbody className="nonEntity">
                        {conversions.map((conversion, index) => (
                            <DataRow
                                key={conversion.id} id={conversion.id!}
                                className={conversion.dateCompleted ? 'complete' : ''}
                                action={ id => this.props.history.push(`/conversion/${id}/edit`) }
                            >
                                <DataCell className="number-column">{index + 1}</DataCell>
                                <DataCell id={"conversionDescription" + index}>{conversion.description}</DataCell>
                                <Tooltip
                                    isOpen={this.state.tooltipColumn === "conversionDescription" + index}
                                    target={"conversionDescription" + index}
                                    toggle={(e) => this.toggle(index, e)}
                                >
                                    {conversion.description}
                                </Tooltip>
                                <DataCell id={"conversionTypeDescription" + index}>{conversion.conversionTypeDescription}</DataCell>
                                <Tooltip
                                    isOpen={this.state.tooltipColumn === "conversionTypeDescription" + index}
                                    target={"conversionTypeDescription" + index}
                                    toggle={(e) => this.toggle(index, e)}
                                >
                                    {conversion.conversionTypeDescription}
                                </Tooltip>
                                <DataCell id={"sourceCatalog" + index}>{conversion.source && conversion.source.catalog}</DataCell>
                                <Tooltip
                                    isOpen={this.state.tooltipColumn === "sourceCatalog" + index}
                                    target={"sourceCatalog" + index}
                                    toggle={(e) => this.toggle(index, e)}
                                >
                                    {conversion.source && conversion.source.catalog}
                                </Tooltip>
                                <DataCell id={"targetCatalog" + index}>{conversion.target && conversion.target.catalog}</DataCell>
                                <Tooltip
                                    isOpen={this.state.tooltipColumn === "targetCatalog" + index}
                                    target={"targetCatalog" + index}
                                    toggle={(e) => this.toggle(index, e)}
                                >
                                    {conversion.target && conversion.target.catalog}
                                </Tooltip>
                                <DataCell id={"clientDescription" + index}>{conversion.client && conversion.client.description}</DataCell>
                                <Tooltip
                                    isOpen={this.state.tooltipColumn === "clientDescription" + index}
                                    target={"clientDescription" + index}
                                    toggle={(e) => this.toggle(index, e)}
                                >
                                    {conversion.client && conversion.client.description}
                                </Tooltip>
                                <DataCell>{conversion.createdDate.toLocaleDateString()}</DataCell>
                                <DataCell id={"userCreated" + index}>{conversion.userCreated}</DataCell>
                                <Tooltip
                                    isOpen={this.state.tooltipColumn === "userCreated" + index}
                                    target={"userCreated" + index}
                                    toggle={(e) => this.toggle(index, e)}
                                >
                                    {conversion.userCreated}
                                </Tooltip>
                                {/*<DataCell>{conversion.lastEditedDate}</DataCell>*/}
                                {/*<DataCell>{conversion.userEdited}</DataCell>*/}
                                <DataCell>{conversion.dateCompleted ? conversion.dateCompleted!.toLocaleDateString() : ''}</DataCell>
                                <DataCell className='controls-column'>
                                    <SecureContainer roles={[Roles.developerAdministrationRoleId, Roles.administrationRoleId]}>
                                        <DataTableButton icon='build' id={conversion.id!} title='Administration'
                                            action={id => this.setState({
                                                popupIsOpen: true,
                                                popupConversionId: id,
                                                popupConversionType: conversion.conversionTypeId
                                            })} />
                                    </SecureContainer>
                                    {conversion.refreshRan && <DataTableButton icon='input' id={conversion.id!} title='Edit mappings'
                                        action={id => {
                                            SourceSystem.epicToAMS360Id === conversion.conversionTypeId!.toUpperCase() ?
                                                this.props.history.push(`/conversion/${id}/mapping/entity`) :
                                                this.props.history.push(`/conversion/${id}/mapping/entity/merge`)
                                        }} />}
                                </DataCell>
                            </DataRow>
                        ))}
                    </tbody>
                </DataTable>
                <ConversionAdministrationPopup
                    isOpen={this.state.popupIsOpen}
                    conversionId={this.state.popupConversionId}
                    onClose={this.handleCloseAdministrationPopup}
                    currentUser={this.props.currentUser}
                    conversionType={this.state.popupConversionType}
                />
            </>
        );
    }
}

export default withRouter(connect(
    (state: ApplicationState) => {
        return {
            currentUser: state.authentication && state.authentication.currentUser
        }
    },)(ConversionListPage));
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { ApplicationState } from 'store';
import { SecureContainer } from '../../../basic/Authentication';
import { DataTable, DataRow, DataCell, DataTableButton } from '../../../basic/DataTable';
import * as Roles from 'constants/Roles';
import { ExecutionsClient, ExecutionsModel, IUserLoginDetailsModel, GenericClient, ConversionModel, ConversionClient, ConversionReportsClient, MergeMapLogModel, MergeMapClient } from '../../../../client/ApiClient';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { enqueueNotification } from "../../../../store/Notifications"
import { store } from "AppContext"
import { saveAs } from 'file-saver';

interface MergeSummaryPageArgs {
    conversionId: string,
}

interface MergeSummaryPageState {
    items: MergeMapLogModel[];
    executions: ExecutionsModel[];
    hubConnection: HubConnection | null;
    buttonLock: boolean;
    loading: boolean;
    lastExecution: ExecutionsModel | undefined;
    copyAll: ExecutionsModel | undefined;
    conversion: ConversionModel | undefined;
    copyDisable: boolean;
    copyReady: boolean;
}


type MergeSummaryPageProps = RouteComponentProps<MergeSummaryPageArgs> & {
    currentUser: IUserLoginDetailsModel | undefined,
};

class MergeSummaryPage extends React.Component<MergeSummaryPageProps, MergeSummaryPageState>{
    private readonly client: GenericClient = new GenericClient();
    private executions: ExecutionsClient = new ExecutionsClient();
    private conversion: ConversionClient = new ConversionClient();
    private report: ConversionReportsClient = new ConversionReportsClient();
    private readonly merge: MergeMapClient = new MergeMapClient();
    private readonly mergeNames =
        [{ mergeName: 'divisionmerge', entity: 'Divisions' },
        { mergeName: 'branchmerge', entity: 'Branches' },
        { mergeName: 'billingtransactions', entity: 'BillingTransactions' },
        { mergeName: 'paymentplans', entity: 'PaymentPlans' },
        { mergeName: 'departmentmerge', entity: 'Departments' },
        { mergeName: 'groupmerge', entity: 'Groups' },
        { mergeName: 'brokersmerge', entity: 'Brokers' },
        { mergeName: 'companymerge', entity: 'Companies' },
        { mergeName: 'employeemerge', entity: 'Employees' },
        { mergeName: 'kindoflossmerge', entity: 'KindsOfLoss' },
        { mergeName: 'lineofbusinessmerge', entity: 'LineOfBusiness' },
        { mergeName: 'profiles', entity: 'ProfileQuestions' }];

    constructor(props: MergeSummaryPageProps) {
        super(props);
        this.state = {
            items: [],
            executions: [],
            hubConnection: null,
            buttonLock: true,
            loading: false,
            lastExecution: undefined,
            copyAll: undefined,
            conversion: undefined,
            copyDisable: false,
            copyReady: false,
        }
    }

    componentDidMount() {
        this.getEntities();
        this.currentConversion();
        this.hubConnection();
        this.getExecutions();
        this.UIController();
    }

    getEntities = () => {
        this.merge.mergeMapLog(this.props.match.params.conversionId)
            .then(response => {
                this.setState({ items: response! })
            })
            .catch(err => {
                console.log(err)
            })
    }

    currentConversion = () => {
        this.conversion.get(this.props.match.params.conversionId)
            .then(response =>
                this.setState({ conversion: response! }, () => {
                    this.buttonLock();
                })
            )
            .catch(error => { })
    }

    buttonLock = () => {
        const conversionId = this.props.match.params.conversionId;
        if (this.state.conversion && this.state.conversion!.dateCompleted === undefined) {
            this.executions.listExecutionsAllTierBoolean(conversionId)
                .then((response) => {
                    if (response) this.setState({ buttonLock: response.isLocked })
                    if (!this.state.conversion!.refreshRan) this.setState({ buttonLock: true })
                })
                .catch(error => { })
        }
    }

    UIController = () => {
        this.conversion.getUIController()
            .then((response) => {
                if (response) this.setState({ copyReady: response!.copyMapMerge })
            })
            .catch(err => console.log(err))
    }

    hubConnection = () => {
        const link = `${process.env.PUBLIC_URL}/executions`
        const hubConnection = new HubConnectionBuilder().withUrl(link).build();
        this.setState({ hubConnection }, () => {
            this.state.hubConnection!
                .start()
                .then(() => console.log('Connection started!'))
                .catch(err => console.log('Error while establishing connection :('));

            this.state.hubConnection!.on('ExecutionUpdated', (execution: ExecutionsModel) => {
                this.getExecutions();
                this.buttonLock();
                if (execution.packageName === 'Merge-00-All.dtsx') {
                    this.getEntities();
                }
                if ((execution.description === "Succeeded" || execution.description === "Completed") && execution.userId === this.props.currentUser!.id) {
                    store.dispatch(enqueueNotification({ type: 'success', message: execution.clientDescription + ' - ' + execution.conversionDescription + ' - ' + execution.title + ' - ' + execution.description }))
                }
                else if ((execution.description === "Failed" || execution.description === "Canceled" || execution.description === "Ended Unexpectedly" || execution.description === "Stopping") && execution.userId === this.props.currentUser!.id) {
                    store.dispatch(enqueueNotification({ type: 'error', message: execution.clientDescription + ' - ' + execution.conversionDescription + ' - ' + execution.title + ' - ' + execution.description }))
                }
            });
        })
    }

    componentWillUnmount() {
        this.state.hubConnection!.stop();
    }

    getExecutions = () => {
        this.executions!.listExecutions(this.props.match.params.conversionId)
            .then(response => {
                if (response) {
                    this.setState({ executions: response }, () => {
                        const executionsReversed = this.state.executions.reverse();
                        const lastExecution = executionsReversed[0]
                        this.setState({ lastExecution })
                        const copyExecution = executionsReversed.find((e: any) => e.packageName === 'Merge-00-All.dtsx');
                        if (copyExecution) this.copyButton(copyExecution);
                    })
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    toEntity = (item: any) => {
        const filterName = this.mergeNames.filter(m => m.entity === item.tableName)
        const routeName = filterName.length > 0 ? filterName[0].mergeName : item.tableName.toLowerCase()
        this.props.history.push({
            pathname: `/conversion/${this.props.match.params.conversionId}/mapping/entity/merge/${routeName}/staging`,
            state: { tabName: 'staging' }
        });
    }

    copyMapping = (id: string) => {
        if (confirm('Are you sure, do you want to continue with Copy Mappings?')) {
            this.setState({ copyDisable: true });
            const userId = this.props.currentUser!.id ? this.props.currentUser!.id : '';
            this.client.copyMappings(this.props.match.params.conversionId, userId, id)
                .then(() => {
                    this.setState({ copyDisable: true });
                })
                .catch(error => { console.log(error) });
        }
    }
    copyButton = (lastCopy: any) => {
        if (lastCopy) {
            this.setState({ copyDisable: true });
            if (lastCopy.endTime) {
                this.setState({ copyDisable: false });
            } else {
                this.setState({ copyDisable: true });
            }
        }
    }

    exportToExcel = (id: string) => {
        this.report.exportToExcel(this.props.match.params.conversionId, id, null, null)
            .then(response => saveAs(response!.data, response!.fileName))
            .catch(error => { store.dispatch(enqueueNotification({ type: 'error', message: 'Conversion created before feature released.' })) });
    }

    public render() {
        const items = this.state.items
        return (
            <>
                <h3 className='mapping-body-header'>All Entities</h3>
                <div className="header-display">
                    <h4 className='statistical-info-header'>Statistical Information</h4>
                    <div>
                        {this.state.copyReady && <SecureContainer roles={[
                            Roles.vertaforeInternalRoleId,
                            Roles.developerAdministrationRoleId,
                            Roles.administrationRoleId
                        ]}>
                            <>
                                {this.state.copyDisable && this.state.conversion && this.state.conversion.refreshRan ?
                                    <img className='copy-loading-indicator' alt="loading" src={'loader.gif'} /> :
                                    this.state.buttonLock || (this.state.conversion && this.state.conversion!.parentConversionId === null) ? null :
                                        <DataTableButton
                                            icon='scanner'
                                            id="MergeAllCopy"
                                            title='Copy Mappings'
                                            action={() => this.copyMapping("MergeAllCopy")} />}
                            </>
                        </SecureContainer>}
                        <SecureContainer roles={[Roles.developerAdministrationRoleId]}>
                            <DataTableButton
                                icon='table'
                                id="ADMTables"
                                title='ADM Tables'
                                action={() => this.props.history.push(`/conversion/${this.state.conversion!.id}/admpage`)} />
                        </SecureContainer>
                        <DataTableButton
                            icon='file_download'
                            id='exportMappingsAll_Merge'
                            title='Export All Mappings to Excel'
                            action={() => this.exportToExcel('exportMappingsAll_Merge')} />
                    </div>
                </div>
                <DataTable>
                    <thead>
                        <tr>
                            <th className="text-column">Entity</th>
                            <th className="number-column">Mapped</th>
                            <th className="number-column">UnMapped</th>
                            <th className="number-column">Percentage Mapped</th>
                        </tr>
                    </thead>
                    <tbody className={"summary"}>
                        {items.map((item, index) => {
                            const tableName = item.tableName!.replace(/[A-Z]/g, ' $&').replace(/\-\s/g, '-').replace('Of', 'of').trim();
                            return <DataRow id={index.toString()} className={'summaryLink'} key={index} onClick={() => this.toEntity(item)}>
                                <DataCell className="text-column-cs">{tableName}</DataCell>
                                <DataCell className="number-column-cs summaryLink">{item.mapCount}</DataCell>
                                <DataCell className="number-column-cs summaryLink">{Number(item.totalCount) - Number(item.mapCount)}</DataCell>
                                <DataCell className="number-column-cs summaryLink">{item.percentMapped} {item.percentMapped !== null && '%'}</DataCell>
                            </DataRow>
                        }
                        )}
                    </tbody>
                </DataTable>
            </>
        )
    }
}

export default connect(
    (state: ApplicationState) => {
        return {
            currentUser: state.authentication && state.authentication.currentUser,
        }
    })(MergeSummaryPage);
